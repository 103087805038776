import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { timeDay, timeMonday, timeMonth } from 'd3-time';
import { useAppSelector } from './hooks';

export type BottomDrawerStatus = 'closed' | 'clipboard' | 'templates';

export enum Period {
  Day,
  TwoDay,
  ThreeDay,
  Week,
  TwoWeek,
  ThreeWeek,
  Month,
}

export interface PlannerState {
  readonly periodStart: Date;
  readonly period: Period;
  readonly workingHours: boolean;
  readonly openClipboard: boolean;
  readonly clipboardLoading: boolean;
  readonly compareTime: Date | null;
  readonly selectedItems: Set<ID>;
  readonly screenCount: number;
}

const initialPeriod = loadZoomLevel() ?? Period.Week;

const initialState: PlannerState = {
  period: initialPeriod,
  periodStart: defaultPeriodStart(initialPeriod),
  workingHours: true,
  openClipboard: false,
  clipboardLoading: false,
  compareTime: null,
  selectedItems: new Set(),
  screenCount: loadScreenCount() ?? 1,
};

const plannerSlice = createSlice({
  name: 'planner',
  initialState,
  reducers: {
    setWorkingHours(state, action: PayloadAction<boolean>) {
      state.workingHours = action.payload;
    },
    openClipboard(state, action: PayloadAction<boolean>) {
      state.openClipboard = action.payload;
    },
    clipboardLoading(state, action: PayloadAction<boolean>) {
      state.clipboardLoading = action.payload;
    },
    smallAdjustment(state, action: PayloadAction<{ multiplier: number }>) {
      const { multiplier } = action.payload;
      if (state.period === Period.Month) {
        state.periodStart = moveVisibleDays(
          state.periodStart,
          state.workingHours,
          multiplier * 7,
        );
      } else {
        state.periodStart = moveVisibleDays(
          state.periodStart,
          state.workingHours,
          multiplier,
        );
      }
    },
    largeAdjustment(state, action: PayloadAction<{ multiplier: number }>) {
      const { multiplier } = action.payload;
      const periodFn = state.period === Period.Month ? timeMonth : timeMonday;
      state.periodStart = periodFn.offset(state.periodStart, multiplier);
    },
    setToday(state) {
      state.periodStart = defaultPeriodStart(state.period);
    },
    periodZoomIn(state) {
      const newPeriod: Period = Math.max(Period.Day, state.period - 1);
      saveZoomLevel(newPeriod);
      state.period = newPeriod;
    },
    periodZoomOut(state) {
      const newPeriod: Period = Math.min(Period.Month, state.period + 1);
      saveZoomLevel(newPeriod);
      state.period = newPeriod;
    },
    setPeriod(state, action: PayloadAction<Period>) {
      state.period = action.payload;
    },
    focusOn(state, action: PayloadAction<{ center: Date }>) {
      state.periodStart = START_FOR_CENTER[state.period](action.payload.center);
    },
    updateCompareTime(state, action: PayloadAction<Date | null>) {
      state.compareTime = action.payload;
    },
    selectItems(state, action: PayloadAction<ID[]>) {
      state.selectedItems = new Set([
        ...state.selectedItems,
        ...action.payload,
      ]);
    },
    toggleSelectItem(state, action: PayloadAction<ID>) {
      const id = action.payload;
      if (state.selectedItems.has(id)) {
        state.selectedItems.delete(id);
      } else {
        state.selectedItems.add(id);
      }
    },
    deselectItem(state, action: PayloadAction<ID>) {
      const id = action.payload;
      state.selectedItems.delete(id);
    },
    deselectAllItems(state) {
      state.selectedItems = new Set();
    },
    screenCount(state, action: PayloadAction<number>) {
      state.screenCount = action.payload;
      saveScreenCount(action.payload);
    },
  },
});

export const {
  setWorkingHours,
  openClipboard,
  clipboardLoading,
  smallAdjustment,
  largeAdjustment,
  setToday,
  periodZoomIn,
  periodZoomOut,
  setPeriod,
  focusOn,
  updateCompareTime,
  selectItems,
  toggleSelectItem,
  deselectItem,
  deselectAllItems,
  screenCount,
} = plannerSlice.actions;

export const plannerReducer = plannerSlice.reducer;

function saveZoomLevel(period: Period) {
  window.localStorage.setItem('zoom-level', period.toString());
}

function loadZoomLevel(): Period | null {
  const value = window.localStorage.getItem('zoom-level');
  if (value == null) return null;
  const int = parseInt(value, 10);
  if (isNaN(int)) return null; // Shouldn't happen unless people mess with it
  return int;
}

function saveScreenCount(count: number) {
  window.localStorage.setItem('screen-count', count.toString());
}

function loadScreenCount(): number | null {
  const value = window.localStorage.getItem('screen-count');
  if (value == null) return null;
  const int = Number(value);
  if (Number.isInteger(int)) return int;
  return null;
}

export function defaultPeriodStart(period: Period, date?: Date) {
  if (date == null) date = new Date();
  if (period < Period.ThreeDay) return timeDay(date);
  const monday = date.getDay() === 1;
  return timeDay.offset(timeDay.floor(new Date()), monday ? -2 : -1);
}

const WEEKEND_DAYS = [0]; // returned by getDay()
function moveVisibleDays(
  periodStart: Date,
  workingHours: boolean,
  count: number,
) {
  let outputDate = timeDay.offset(periodStart, count);
  // Move past any weekend
  while (workingHours && WEEKEND_DAYS.includes(outputDate.getDay())) {
    outputDate = timeDay.offset(outputDate, count < 1 ? -1 : 1);
  }
  return outputDate;
}

const START_FOR_CENTER: Record<Period, (d: Date) => Date> = {
  [Period.Day]: timeDay,
  [Period.TwoDay]: timeDay,
  [Period.ThreeDay]: (d) => timeDay.offset(timeDay(d), -1),
  [Period.Week]: (d) => timeDay.offset(timeDay(d), -2),
  [Period.TwoWeek]: (d) => timeDay.offset(timeDay(d), -6),
  [Period.ThreeWeek]: (d) => timeDay.offset(timeDay(d), -13),
  [Period.Month]: (d) => timeDay.offset(timeMonday(d), -14),
};

export const useCompareTime = () =>
  useAppSelector((s) => s.planner.compareTime);

export function hasChanged(
  compareTime: Date | null,
  lastPositioned: Date | null,
) {
  if (compareTime == null || lastPositioned == null) return false;
  return lastPositioned > compareTime;
}

export function useHasChanged(lastPositioned: Date | null) {
  return hasChanged(useCompareTime(), lastPositioned);
}

export function useScreenCount() {
  return useAppSelector((s) => s.planner.screenCount);
}
